.anc-card {
  border-radius: $border-radius-lg;
  background: #fbfdfc;
  box-shadow: $box-shadow;
  padding: 10px 0 !important;
}

.anc-badge {
  font-size: 10px;
  margin-top: -2px;
}

.anc-labtest-orderall {
  padding: 3px 20px;
  border-radius: 3px;
}

.anc-accordion-header {
  padding: 10px 8px;
  display: grid;
  grid-template-columns: 2.2fr 1.5fr 1fr;
}

.anc-accordion-body {
  padding: 6px 8px;
  display: grid;
  grid-template-columns: 2.2fr 1.5fr 1fr;

  button {
    text-transform: capitalize;
    padding: 3px 20px;
    border-radius: 3px;
  }
}

// Order labtest modal
.order-anc-labtest-search {
  width: 400px;
  height: 50px;
  background: rgba($light, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba($primary, 0.4);
  border-radius: 6px;
  padding: 2px 11px;

  input {
    width: 80%;
    outline: none;
    border: none;
    background: transparent;

    &::placeholder {
      color: $dark;
      font-weight: 600;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.order-anc-labtest-list {
  width: 100%;
  border: 1px solid $light;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
}

@media screen and (max-width: 576px) {
  .anc-accordion-header {
    display: none;
  }

  .anc-accordion-body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
