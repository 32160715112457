/* stylelint-disable */

.mentions {
  line-height: 22px;

  .mentions__highlighter {
    padding: 0.95rem 1.1rem 0.8rem;

    strong {
      color: $secondary;
    }
  }

  .mentions__suggestions {
    border-radius: 6px;
    box-shadow: $box-shadow;
    padding: 0.6rem 0;
    overflow-y: auto;
    max-height: 40vh;
  }

  .mentions__suggestions__item {
    padding: 0.3rem 1rem;

    &:hover {
      color: $secondary;
      background: $gray-400;
    }
  }
}

.audit-log-navbar {
  padding: 5px 20px !important;
  margin-bottom: 13px;
  height: 30px !important;
  background-color: #f6fafb;
  max-width: 200%;
}

.light-blue {
  background-color: #D9E9EE;
  padding-bottom: 3px;
}

.log-notes {
  margin: 0 28px;
}

.navbar-nav .audit-log-nav-link {
  transition: color 0.3s ease-in-out;
  font-weight: bolder;
  font-size: 18px;
  padding-bottom: 2px;
  
  &.active {
    color: $primary !important;
    border-bottom: 3px solid $primary;
  }
}

.audit-log-bg {
  background-color: #E9F3F5 !important;
  font-size: 15px;
  border: none;

  span {
    margin-left: 5px;
    font-size: 10px;
    opacity: 0.8;
  }
}

.small-tabs .nav-link {
  font-size: 0.75rem;  
  padding: 0.25rem 0.5rem;  
  text-transform: capitalize;
  margin-bottom:  3px;
}
