.vc-card {
  .p-3 {
    padding: 9px 0 !important;
  }
}

.vaccination-checklist-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.expanded {
    padding: 8px 15px 0;
  }

  &.collapsed {
    padding: 8px 15px;
  }

  .fw-bold {
    text-transform: capitalize;
  }

  .badge {
    margin-top: -2px;

    &.bg-body-light {
      background-color: $secondary-light !important;
      text-transform: uppercase;
    }
  }
}

.vc-accordion-collapse {
  margin: 10px 0 8px;
  border-top: 1px solid $gray-400;
  padding: 0 13px;
  width: 100%;

  table {
    width: 100%;

    thead {
      width: 100%;

      tr {
        width: 100%;
        display: grid;
        grid-template-columns: 0.9fr 0.4fr 0.4fr 0.8fr 0.6fr 0.6fr  0.4fr 0.35fr;
        padding: 8px 5px 8px 0;
      }
    }

    th {
      word-break: break-all;

      &.update {
        display: flex;
        justify-content: flex-end;
      }
    }

    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      tr {
        width: 100%;
        display: grid;
        grid-template-columns: 0.9fr 0.4fr 0.4fr 0.8fr 0.6fr 0.6fr  0.4fr 0.35fr;
        border-radius: 3px;
        border: 1px solid $gray-400;
        padding: 8px 3px;
        background: rgba($light, 0.1);
        box-shadow: $box-shadow-inset;

        .sequence-number {
          margin-left: 30px;
        }

        td:not(:last-child) {
          padding-right: 4px;
        }

        td {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          word-break: break-all;

          .title {
            display: none;
          }

          &.update {
            display: flex;
            justify-content: flex-end;

            .btn {
              padding: 4px 10px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.vc-loading {
  width: 96%;
  height: 20px;
  border-radius: 6px;
  background-color: $gray-300;
}

@media screen and (max-width: 992px) {
  .vc-accordion-collapse {
    table {
      thead {
        display: none;
      }

      tbody {
        margin-top: 10px;

        tr {
          padding-left: 6px;
          padding-right: 6px;
          display: flex;
          flex-flow: row wrap;
          position: relative;

          td {
            .title {
              display: block;
            }
          }

          .sequence-number {
            margin: 0;
          }

          .title {
            font-weight: 600;
          }

          .name {
            width: 100%;
            max-width: 100%;
            display: flex;
            column-gap: 10px;
          }

          .sequence,
          .dosage,
          .route,
          .site,
          .date {
            width: 50%;
            max-width: 50%;
            display: flex;
            column-gap: 10px;
          }

          .status {
            position: absolute;
            right: 5px;
            top: -1px;
          }
        }
      }
    }
  }
}

.update-vaccination-modal {
  .update-vac-body {
    min-height: 200px !important;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .save-button-area {
    max-width: 100%;
    position: relative;
  }

  .btn {
    &.btn-body-light {
      color: white;
    }
  }
}

.update-vac-input {
  padding: 7.5px 10px !important;
}
