.enroll-btn {
  text-transform: none;
  font-size: 1.1rem;

  &.main {
    &.btn-primary {
      color: white;
      background-color: #5b74c6;
      border: 1px solid #5b74c6;
    }
  }

  &.proceed {
    &.btn-primary {
      background-color: #0590f9;
      border: 1px solid #0590f9;
      color: white;
    }
  }

  &.cancel {
    &.btn-primary {
      color: #0590f9;
      background-color: #ecf4f7;
    }
  }
}

.careplan-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.careplan-card {
  border-radius: $border-radius-lg;
  background-color: #fbfdfc;
  box-shadow: $box-shadow;
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.8rem 0 0.5rem;
}

.careplan-checklist-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  &.expanded {
    padding: 8px 15px 0;
  }

  &.collapsed {
    padding: 8px 15px;
  }

  .fw-bold {
    text-transform: capitalize;
    margin: 0;
  }

  .badge {
    margin-top: -2px;

    &.bg-body-light {
      background-color: $secondary-light !important;
      text-transform: uppercase;
    }
  }
}

.careplan-accordion-collapse {
  margin: 10px 0 8px;
  border-top: 1px solid $gray-400;
  width: 100%;

  table {
    width: 100%;

    thead {
      width: 100%;
      font-size: 1.1rem;

      tr {
        width: 100%;
        display: grid;
        grid-template-columns: 2.5fr 1fr 1fr;
        padding: 14px 15px 12px;

        .header-status {
          text-align: right;
        }
      }
    }

    th {
      word-break: break-all;
    }

    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 0 15px;

      .body-row {
        border-radius: 3px;
        border: 1px solid $gray-400;
        padding: 12px 5px 9px;
        background: rgba($light, 0.1);
        box-shadow: $box-shadow-inset;
      }

      .row-field {
        column-span: 3;
        word-break: break-all;
        border: 1px solid $gray-400;
        border-radius: 5px;
        margin: 7px 3px 0;
        padding: 10px 15px 0;

        textarea {
          width: 100%;
          background: transparent;
          outline: none;
          border: 1px;
          height: 40px;
        }
      }

      tr {
        width: 100%;
        display: grid;
        grid-template-columns: 2.5fr 1fr 1fr;

        td:not(:last-child) {
          padding-right: 4px;
        }

        .status {
          display: flex;
          justify-content: flex-end;

          .completed {
            color: $success;
          }

          .not-completed {
            color: $secondary;
          }
        }

        td {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          word-break: break-all;
          font-size: 1rem;

          .title {
            display: none;
          }
        }
      }
    }
  }
}

.careplan-midsection {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 11px;
  align-items: center;
  border-bottom: 1px solid $gray-300;

  div {
    color: $secondary;
  }

  button {
    color: $primary;
  }
}

.careplan-checklist-field {
  width: 100%;
  background: transparent;

  label {
    font-style: italic;
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .careplan-accordion-collapse {
    table {
      thead {
        display: none;
      }

      tbody {
        margin-top: 10px;

        tr {
          padding-left: 6px;
          padding-right: 6px;
          display: flex;
          flex-flow: row wrap;
          position: relative;

          td {
            .title {
              display: block;
            }

            .value {
              margin-left: 8px;
            }
          }

          .title {
            font-weight: 600;
          }

          .name {
            width: 100%;
            max-width: 100%;
            display: flex;
            column-gap: 10px;
          }

          .activity,
          .date-completed,
          .status {
            width: 100%;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.careplan-modal-pill-container {
  display: flex;
  row-gap: 13px;
  column-gap: 13px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.careplan-modal-pill {
  display: flex;
  align-items: center;
  color: $gray-900;
  border: 1px solid $gray-900;
  width: fit-content;
  padding: 7px 10px;
  column-gap: 5px;
  border-radius: 23px;

  .eha-close {
    color: rgb(228 95 95);
    margin-top: 2px;
  }
}

.careplan-hover-container {
  position: absolute;
  width: 30rem;
  padding: 20px 23px;
  background: transparent;
  min-height: 300px;
  border-radius: 10px;
  background-color: white;
  top: 50px;
  z-index: 1000;

  h5 {
    padding-bottom: 5px;
    border-bottom: 2px solid $gray-300;
  }

  .subtitle {
    font-size: 0.9rem;
    font-weight: 700;
  }
}
