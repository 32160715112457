$tabbar-height: 2.8rem;
$header-height: 10rem;
$header-height-small: 4rem;
$actionbar-height: 2.6rem;
$record-padding: 0.6rem;

.patient-record-container {
  display: flex;
  flex-direction: column;
  padding: $record-padding $record-padding 0;
  background: $gray-900;
  background-image: linear-gradient($gray-800, $gray-900);
  position: relative;

  .tabbar {
    height: $tabbar-height;
    background: $purple;
    box-shadow: $box-shadow;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    display: flex;
    align-items: center;
    margin-top: $topnav-height; // To accomodate the TopNav component
  }

  .header {
    height: $header-height + $actionbar-height;
    box-shadow:
      0 2px 5px rgba($gray-900, 0.3),
      0 1rem 0 rgba($gray-900, 0.1);
    z-index: 1;
    background: $white;
  }

  .body {
    display: flex;
    background: $gray-200;
    height:
      calc(
        100vh - ($tabbar-height + $header-height + $actionbar-height + $record-padding)
      );

    &.bg {
      background: $white url("/img/bg.jpg");
      background-size: cover;
    }
  }

  // classes for change to patient details record and the chatter comp inside
  .patient-record-body {
    display: flex;
    height: auto;
  }

  .display {
    flex: 1;
    overflow-y: auto;
  }

  @media screen and (max-height: 750px), screen and (max-width: 768px), print {
    bottom: auto;

    .header,
    .patient-header,
    .body {
      height: auto;
      z-index: 0;
      position: relative;
    }

    .header {
      z-index: 1;
    }

    .body {
      overflow: hidden;
    }

    .display {
      overflow-y: visible;
      z-index: 0;
      min-height: 40rem;
    }
  }

  @media screen and (max-width: 768px) {
    .display {
      margin-top: 4rem;
    }

    .header {
      box-shadow: 0 1px 3px rgba($gray-900, 0.2);
    }
  }

  &.small-header {
    .header {
      height: $header-height-small + $actionbar-height;
    }

    .body {
      height:
        calc(
          100vh - ($tabbar-height + $header-height-small + $actionbar-height + $record-padding)
        );
    }

    @media screen and (max-height: 750px),
      screen and (max-width: 768px),
      print {
      .header,
      .patient-header,
      .body {
        height: auto;
      }
    }
  }
}

.patient-record-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  .close-record,
  .minimize-record {
    color: $white;
    padding: 0 0.6rem;

    i {
      line-height: 1.3rem;
    }
  }

  .divider {
    opacity: 0.4;
    height: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    .close-record {
      span {
        display: none;
      }
    }
  }
}

.patient-tabs {
  flex: 1;
  max-width: calc(100% - 15rem);

  @media screen and (max-width: 768px) {
    max-width: calc(100% - 15vw);
  }
}

.patient-tab {
  display: flex;
  align-items: center;
  height: $tabbar-height;
  color: $white;
  font-size: 0.9rem;
  border-right: 1px solid rgba($light, 0.2);
  flex: 1 1 auto;
  width: 10%;
  max-width: 20rem;
  cursor: pointer;

  &:first-child {
    max-width: 16rem;
  }

  .patient-tab-inner {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 0.8rem;
      background-image: linear-gradient(to right, transparent, $purple);
      right: 0;
      top: 6px;
      bottom: 6px;
    }
  }

  &.active {
    background: $white;
    color: $body-color;
    border-radius: $border-radius $border-radius 0 0;
    border: 0;

    .patient-tab-inner::after {
      background-image: linear-gradient(to right, transparent, $white);
    }
  }

  .patient-tab-title {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }

  @include media-breakpoint-down(sm) {
    &:not(&.active) {
      .eha-close {
        display: none;
      }
    }
  }
}

.patient-header {
  height: $header-height;

  .patient-portrait,
  .patient-figures {
    border-right: 1px solid $light;
  }

  .patient-details {
    padding-left: 1.5rem;
  }

  // Added for flagging patients
  .patient-flagged {
    font-size: 12px;
    padding-top: 3px;
    width: 140px;
    height: 21px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: rgb(244 125 130 / 20%);
    color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .patient-name {
    display: inline-block;
    width: fit-content !important;
  }

  .patient-member {
    width: 100%;
    padding: 1rem;
    display: flex;
  }

  @media screen and (max-width: 1200px) {
    .round-figure {
      .dot {
        width: 5rem;
        height: 5rem;
      }

      .figure-big {
        font-size: 1.6rem;
      }
    }

    .patient-flagged {
      margin-top: 4px;
      font-size: 11px;
      padding-top: 3px;
      width: 120px;
      height: 20px;
      margin-bottom: 2px;
    }
  }

  @media screen and (max-width: 992px) {
    .round-figure {
      .dot {
        width: 4.5rem;
        height: 4.5rem;
      }

      .figure-big {
        font-size: 1.4rem;
      }
    }

    .patient-flagged {
      margin-top: 4px;
      font-size: 11px;
      padding-top: 3px;
      width: 120px;
      height: 20px;
      margin-bottom: 2px;
    }
  }

  @media screen and (min-width: 766px) and (max-width: 880px) {
    .patient-flagged {
      margin-top: 10px;
      font-size: 11px;
      padding-top: 3px;
      width: 120px;
      height: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    .label {
      font-size: 0.9rem;
    }

    .patient-portrait {
      border: 0;
      border-bottom: 1px solid $light;
    }

    .patient-figures {
      width: 50%;
      float: left;
      border: 0;
      padding-top: 1rem;
      padding-bottom: 0.25rem;
    }

    .patient-member {
      width: 50%;
    }
  }

  @media screen and (max-width: 576px) {
    .patient-figures,
    .patient-member {
      width: 100%;
    }
  }
}

.patient-image {
  line-height: 0;
  width: 7.4rem;
  border-radius: 50%;
  box-shadow: 0 0 8px $gray-900 inset;
  flex-shrink: 0;

  img {
    border-radius: 50%;
    opacity: 0.9;
  }

  &.sm {
    width: 4rem;
  }

  &.xs {
    width: 2rem;
  }
}

.small-header .patient-header,
.patient-header.minimized {
  height: $header-height-small;

  .patient-details {
    padding-left: 1rem;
    display: flex;
    align-items: center;
  }

  .patient-image {
    width: 2.8rem;
  }

  .patient-member {
    width: auto;
    padding: 0.2rem;
  }

  h2 {
    font-size: 1rem;
  }

  @include media-breakpoint-down(md) {
    .patient-member {
      display: none;
    }
  }
}

.round-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .dot {
    box-shadow: $box-shadow-inset;
    background: $gray-100;
    border: 1px solid rgba($light, 0.5);
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
  }

  .figure-big {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;

    span {
      display: block;
      font-size: 0.9rem;
      line-height: 1em;
    }
  }
}

.patient-action-bar {
  height: $actionbar-height;
  justify-content: space-between;

  .patient-bar-flow-status {
    width: calc(100% - 4rem);
    display: flex;
    justify-content: flex-end;
  }
}

.timeline {
  max-height: 500px;
  overflow-y: auto;

  .timeline-item {
    margin-left: 4rem;
    padding-top: 0.6rem;
    margin-top: 0.3rem;
    position: relative;
    font-size: 0.9rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      border-left: 0.3rem dotted $light;
      top: 0;
      bottom: 0;
      left: -2.8rem;
    }

    &:first-child {
      &::before {
        top: 2rem;
      }
    }

    &:last-child {
      &::before {
        bottom: auto;
        height: 2rem;
      }
    }
  }

  .accordion-button {
    background: $light;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &.collapsed {
      border-radius: $border-radius;
    }

    &:focus {
      box-shadow: none;
    }

    &::after {
      display: none;
    }

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 10px solid $light;
      position: absolute;
      left: -10px;
      top: calc(50% - 8px);
    }
  }

  .accordion-collapse {
    background: $gray-200;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .timeline-title {
    font-weight: 600;
  }

  .timeline-icon {
    position: absolute;
    left: -4rem;
    border-radius: 100%;
    width: 2.6rem;
    height: 2.6rem;
    background: $gray-200;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $purple;
    font-size: 1.2rem;
  }
}

/// Style for patientdetails due to addition of ChatterNoLogInput component
.patient-record-with-chatter {
  background: $white url("/img/bg.jpg");
  background-size: cover;
}

.patient-record-chatter-section {
  background-color: #d9e9ee;
}

@media screen and (max-height: 750px), screen and (max-width: 768px), print {
  .patient-record-body {
    height: auto;
    z-index: 0;
    position: relative;
    overflow: hidden;
  }
}
