.confirm-sample-collection {
  .sample-collection-secondary-text {
    color: #5a7574;
  }

  .samples-to-collect {
    border: 1px solid #e5ebeb;
    padding: 15px;
    border-radius: 10px;
  }

  .cannot-update {
    background-color: #fdeeef;
    padding: 6px 5px 3px;
  }
}

.collect-sample-btn {
  text-transform: capitalize;

  &.proceed {
    &.btn-primary {
      background-color: #0590f9;
      border: 1px solid #0590f9;
      color: white;
    }
  }

  &.cancel {
    &.btn-primary {
      color: #0590f9;
      background-color: #ecf4f7;
    }
  }
}
