// React select

/* stylelint-disable */

.select {
  &.select-dark {
    box-shadow: $box-shadow-inset;
    border-radius: $border-radius-sm;
    padding: 0rem;
    background: rgba($primary, 0.2);
    border: 1px solid transparent;
  }

  &.select-filter {
    background: lighten($purple, 3);
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius-sm;

    .select__value-container {
      padding: 0.5rem 0 0.4rem 1rem;
    }

    .select__single-value,
    .select__input-container {
      color: $white;
    }

    .select__indicator-separator {
      background: rgba($white, 0.2);
    }

    .select__indicator,
    .select__indicator:hover {
      color: $white;
    }

    .select__placeholder {
      color: rgba($white, 0.6);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.select-small {
    .select__control {
      min-height: 0;
    }
    .select__value-container {
      padding: 0.2rem 0 0.1rem 0.6rem;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__indicator {
      padding: 0 0.5rem;
    }
  }

  .select__control {
    border: 0;
    background: 0;
    box-shadow: none;
    height: auto;
  }

  .select__value-container {
    padding: 1rem 1.2rem 0.8rem;
  }

  .select__value-container--has-value.select__value-container--is-multi {
    padding-left: 1rem;
  }

  .select__input-container {
    padding: 0;
    margin: 0;
  }

  .select__input {
    margin: 0;
  }

  .select__placeholder {
    &::before {
      content: '';
      display: block;
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $primary;
      position: absolute;
      left: 1.2rem;
      bottom: 1rem;
    }
  }

  .select__multi-value {
    background: $light;
  }

  .select__indicator,
  .select__indicator:hover {
    color: $primary;
  }

  .select__menu {
    margin-top: 1px;
    //margin-left: -0.6rem;
    //width: calc(100% + 1.2rem);
  }

  .select__option--is-selected {
    background: $primary;
  }
}

/* stylelint-enable */

// Single form field

.form-group {
  background: rgba($light, 0.2);
  box-shadow: $box-shadow-inset;
  border: 1px solid rgba($light, 0.3);
  border-radius: $border-radius-sm;
  padding: 0 0.6rem;
  position: relative;
  margin-bottom: $spacer;

  .input-wrapper,
  .select-wrapper {
    position: relative;
    display: flex;
    align-items: baseline;

    &.empty::before {
      content: "";
      display: block;
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $primary;
      position: absolute;
      left: 1.2rem;
      bottom: 1rem;
    }
  }

  label {
    display: block;
    font-family: Asap, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.94rem;
    color: $gray-800;
    margin: 0.4rem 0 0;

    &.careplan-label {
      font-weight: 100 !important;
      color: #464545;

      .careplan-optional {
        font-size: 13px;
        color: $gray-700;
        font-style: italic;
        margin-left: 4px;
      }
    }
  }

  .form-text {
    margin: 0.2rem 0 0;
  }

  input,
  textarea {
    padding: 1rem 1.2rem 0.8rem;
  }

  input.sm {
    padding: 0.6rem 0.4rem 0.4rem;
  }

  select {
    padding: 1rem 1.2rem 0.8rem 1rem;
  }

  input,
  select,
  textarea {
    border: 0;
    background: none;
    width: 100%;
    border-radius: $border-radius-sm;

    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible {
      border: 0;
      outline: 0;
    }
  }

  .unit {
    color: $gray-700;
    padding-right: 0.5rem;
  }

  &:focus-within {
    background: rgba($light, 0.4);
    border: 1px solid rgba($light, 0.6);

    /* stylelint-disable */

    .input-wrapper::before,
    .select__placeholder {
      display: none;
    }

    /* stylelint-enable */
  }

  &:hover {
    background: rgba($light, 0.4);
  }

  &.required {
    background: rgba($primary, 0.1);

    label::after {
      content: "*";
      font-size: 1.6rem;
      position: absolute;
      margin-left: 0.2rem;
      top: -0.1rem;
    }

    &:focus-within {
      background: rgba($primary, 0.2);
      border: 1px solid $light;
    }

    &:hover {
      background: rgba($primary, 0.2);
    }
  }

  &.careplan-required {
    label::after {
      content: "*";
      font-size: 1.6rem;
      position: absolute;
      margin-left: 0.2rem;
      top: -0.1rem;
      color: #f95959;
    }

    &:focus-within {
      background: rgba($primary, 0.2);
      border: 1px solid $light;
    }

    &:hover {
      background: rgba($primary, 0.2);
    }
  }

  &.error {
    background: rgba($danger, 0.1);
    border: 1px solid rgba($danger, 0.15);

    &:focus-within {
      background: rgba($danger, 0.15);
      border: 1px solid rgba($danger, 0.2);
    }

    &:hover {
      background: rgba($danger, 0.15);
    }
  }

  &.form-group-dark {
    background: rgba($primary, 0.2);
    border: 1px solid transparent;

    &:focus-within {
      background: rgba($primary, 0.3);
      border: 1px solid rgba($primary, 0.3);
    }

    &:hover {
      background: rgba($primary, 0.3);
    }
  }

  &.form-group-invert {
    background: rgba($info, 0.8);
    border: 1px solid transparent;
    color: $white;

    .form-label,
    input {
      color: $white;
    }

    &:focus-within {
      background: $info;
      border: 1px solid rgba($primary, 0.3);
    }

    &:hover {
      background: $info;
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
}

.form-group-multi {
  background: rgba($light, 0.2);
  box-shadow: $box-shadow-inset;
  border: 1px solid rgba($light, 0.3);
  border-radius: $border-radius-sm;
  position: relative;

  .form-group {
    background: none;
    box-shadow: none;
    border: 1px solid transparent;
    margin: 0;
    padding: 0.1rem 0.6rem;

    .input-wrapper {
      &.empty::after {
        content: "";
        display: block;
        width: calc(100% - 3.7rem);
        height: 1px;
        border-bottom: 1px solid $light;
        position: absolute;
        left: 2.5rem;
        bottom: 1rem;
      }
    }

    &:hover,
    &:focus-within {
      background: rgba($light, 0.2);
    }

    &:focus-within {
      .input-wrapper.empty::after {
        display: none;
      }
    }

    &.required {
      border: 1px solid transparent;
    }
  }

  .row {
    margin: 0;
  }

  .row > div {
    padding: 0;
    display: flex;
  }
}

/* stylelint-disable */

.form-group {
  .PhoneInputCountry {
    left: 1.2rem;
  }

  .PhoneInputCountryIconImg {
    border-radius: 2px;
  }

  .PhoneInputCountryIcon {
    border-radius: 2px;
    box-shadow: 0 0 2px $gray-600;
  }
}

/* stylelint-enable */

.delete-button {
  position: relative;
  display: inline-block;

  .confirmation-dialog {
    z-index: 1002;
    text-align: left;
    position: absolute;
    bottom: -1rem;
    left: -1rem;
    background: $white;
    width: 19rem;
    padding: 0.8rem 1rem 1rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow-lg;

    &.bottom-right {
      left: auto;
      right: -1rem;
    }

    &.top-right {
      bottom: auto;
      left: auto;
      top: -1rem;
      right: -1rem;
      box-shadow:
        $box-shadow-lg,
        0 0 20px 10px rgba($gray-700, 0.2);
    }

    input {
      padding: 0.5rem;
    }
  }
}

.confirm-button {
  position: relative;
  display: inline-block;

  .confirmation-pointer {
    z-index: 1002;
    text-align: left;
    position: absolute;
    background: $white;
    width: 19rem;
    padding: 0.8rem 1rem 1rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow-lg;
    bottom: calc(100% + 1.5rem);
    left: calc(50% - 19rem / 2);

    input {
      padding: 0.5rem;
    }

    &::before {
      content: "";
      width: 1rem;
      height: 1rem;
      background: $white;
      transform: rotate(45deg);
      position: absolute;
      bottom: -0.4rem;
      left: calc(50% - 0.5rem);
      box-shadow: 2px 2px 2px rgba($gray-900, 0.15);
    }

    &.left {
      left: auto;
      right: -1rem;

      &::before {
        left: auto;
        right: 1.5rem;
      }
    }

    &.right {
      left: -1rem;

      &::before {
        left: 1.5rem;
      }
    }

    &.bottom {
      bottom: auto;
      top: calc(100% + 1rem);

      &::before {
        bottom: auto;
        top: -0.4rem;
        transform: rotate(-135deg);
      }
    }

    &.middle {
      bottom: -1rem;

      &.left {
        right: calc(100% + 1rem);

        &::before {
          bottom: 1.5rem;
          left: auto;
          right: -0.4rem;
          transform: rotate(-45deg);
        }
      }

      &.right {
        left: calc(100% + 1rem);

        &::before {
          bottom: 1.5rem;
          left: -0.4rem;
          transform: rotate(135deg);
        }
      }
    }
  }
}

.confirmation-backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($body-color, 0.3);
}

.multi-patient-dialog {
  width: 400px;
  background-color: white;
  border-radius: 4px;
  margin-top: -2%;
  padding: 15px;
}

@media screen and (max-width: 576px) {
  .multi-patient-dialog {
    width: 80%;
  }
}
